import { StateCreator } from "zustand";
import { CalendarSettings } from "../../types/calendar";
import dayjs from "dayjs";

export interface StudyScheduleSlice {
  initialDate: any;
  dateRange: [string, string];
  calendarSettings: CalendarSettings;
  calView: string;
  changeView: string;
  selectedDate: any;
  currentMonth: string;
  apiDate: any;
  setInitialDate: (date: any) => void;
  setDateRange: (date: [string, string]) => void;
  setCalendarSettings: (settings: CalendarSettings) => void;
  setCalView: (calView: string) => void;
  setChangeView: (changeView: string) => void;
  setSelectedDate: (date: any) => void;
  setCurrentMonth: (month: string) => void;
  setApiDate: (apiDate: any) => void;

}

export const studyScheduleSlice: StateCreator<StudyScheduleSlice> = (
  set,
  get
) => ({
  initialDate: new Date().toISOString().split("T")[0],
  dateRange: ["", ""],

  calendarSettings: {
    id: null,
    userId: null,
    dateForm: null,
    timeForm: null,
    notifyStudy: true,
    notifyExercise: true,
    notifyTest: true,
    notifyExam: true,
    notifyLearnMore: true,
    timeLimitBefore: null,
    timeTypeBefore: null,
    selectedOption: null,
  },
  calView: "dayGridMonth",
  changeView: "dayGridMonth",
  currentMonth: dayjs(new Date()).format("YYYY-MM-DD"),
  selectedDate: new Date(),
  apiDate: new Date(),
  setInitialDate: (date) => set({ initialDate: date }),
  setDateRange: (date) => set({ dateRange: date }),
  setCalendarSettings: (setting) => set({ calendarSettings: setting }),
  setCalView: (view) => set({ calView: view }),
  setChangeView: (view) => set({ changeView: view }),
  setSelectedDate: (date) => set({ selectedDate: date }),
  setCurrentMonth: (month) => set({ currentMonth: month }),
  setApiDate: (date) => set({ apiDate: date }),


});
