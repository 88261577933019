import { StateCreator } from "zustand";

interface UserType {
  name?: string;
  isLoginFirst?: boolean;
  academicLevel?: any;
  cardId?: any;
  dateOfBirth?: string | null;
  districtId?: any;
  email?: string;
  fullName?: string;
  image?: string;
  language?: string;
  location?: string;
  permissions?: any;
  phoneNumber?: string;
  provinceId?: any;
  school?: any;
  specialized?: any;
  timeZone?: any;
  wardId?: any;
  username?: string;
  gender?: string;
  address?: string;
  studentFavorites?: any;
  industries?: any;
  schoolId?: any;
  profileStory?: string;
  province?: any;
  district?: any;
  ward?: any;
  [key: string]: any;
}

export interface UserInfoState {
  infoUser: UserType;
  setUserInfo: (dataInfo: any) => void;
}

const initUserInfo: UserType = {
  name: "",
  isLoginFirst: true,
  academicLevel: null,
  cardId: null,
  dateOfBirth: null,
  districtId: null,
  email: "",
  fullName: "",
  image: "",
  language: "",
  location: "",
  permissions: null,
  phoneNumber: "",
  provinceId: null,
  school: null,
  specialized: null,
  timeZone: null,
  wardId: null,
  username: "",
  gender: "",
  address: "",
  studentFavorites: [], // initialize with an empty array
};

export const userInfoStore: StateCreator<UserInfoState> = (set) => ({
  infoUser: initUserInfo,
  setUserInfo: (infoUser) => set({ infoUser }),
});
