import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "scorm-again/dist/scorm2004.js";
import { getUnitDetail } from "../../service/learning-course";

const ScormVebView = (props: any) => {
	const { id } = useParams();
	const [unitData, setUnitData] = React.useState<any>(null);

	useEffect(() => {
		if (id) {
			const fetchUnitDetail = async () => {
				try {
					const unitDetail = await getUnitDetail(Number(id));
					if (unitDetail) {
						setUnitData(unitDetail?.data.data as any);
					}
				} catch (error) {
				}
			};
			fetchUnitDetail();
		}
	}, [id]);

	const iframeRef = useRef<HTMLIFrameElement>(null);
	const s3BaseUrl = `${process.env.REACT_APP_S3_BASE_URL}`;
	let src = unitData?.scorms?.[0].mainKeyUrl || "";
	src = src.replace(s3BaseUrl, "/s3").split("?")[0];

	useEffect(() => {
		const iframe = iframeRef.current;

		if (iframe) {
			iframe.src = "about:blank";

			const onLoadHandler = () => {
				const iframeDocument =
					iframe.contentDocument || iframe.contentWindow?.document;

				if (iframeDocument) {
					const settings = {};
					// @ts-ignore
					window.API_1484_11 = new Scorm2004API(settings);
					iframe.src = src;
				}

				iframe.removeEventListener("load", onLoadHandler);
			};

			iframe.addEventListener("load", onLoadHandler);
		}
	}, [unitData]);

	return (
		<div
			style={{ position: "relative", width: "100%", paddingBottom: "56.25%" }}
		>
			<iframe
				ref={iframeRef}
				src=""
				allow="fullscreen"
				scrolling="yes"
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					borderRadius: "8px",
				}}
			></iframe>
		</div>
	);
};

export default ScormVebView;
