import { StateCreator } from "zustand";
import { CalendarDateRange } from "../../types/calendar";

export interface BlogAndForum {
  searchValue: string;
  setSearchValue: (value: string) => void;
  refresh: boolean;
  setRefresh: (value: boolean) => void;
}

export const blogAndForumState: StateCreator<BlogAndForum> = (
  set,
  get
) => ({
  searchValue: "",
  refresh: false,
  setSearchValue: (value) => set({ searchValue: value }),
  setRefresh: (value) => set({ refresh: value }),
});
