import { StateCreator } from "zustand";

type UserInfo = {
  anhDaiDien: string | null;
  chucVus: string[];
  chuyenNganh: string | null;
  diaChi: string | null;
  dienThoai: string | null;
  donViCongTac: string | null;
  email: string | null;
  gioiTinh: string | null;
  id: number | null;
  muiGio: string | null;
  ngaySinh: string | null;
  hoVaTen: string | null;
  ngonNgu: string | null;
  trangThai: string | null;
  vaiTros: string[];
  enterpriseUUID: string;
  fullName: string;
};
export interface AuthState {
  isAuthenticated: boolean;
  isSaveInfo?: boolean;
  isEkyc: boolean;
  userId: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  expiresIn?: Date | null;
  edxToken?: string | null;
  user?: UserInfo | null;
  username?: string;
  login: (accessToken: string, refreshToken: string, edxToken: string) => void;
  setExpiresIn: (expiresIn: Date) => void;
  logout: () => void;
  saveUserInfo: (userInfo: object) => void;
  updateIsEkyc: (isEkyc: boolean) => void;
  saveUserId: (userId: string) => void;
  getUserInfo: (userInfo: object) => void;
  rememberInfo: (isSaveInfo: boolean, username: string) => void;
  rememberSession: (refreshToken: string) => void;
}

export const AuthTokenSlice: StateCreator<AuthState> = (set) => ({
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  userId: null,
  isEkyc: false,
  user: null,
  expiresIn: null,
  username: "",
  login: (accessToken: string, refreshToken: string, edxToken: string) => {
    set({ isAuthenticated: true, accessToken, refreshToken, edxToken });
    document.cookie = `accessToken=${accessToken}; path=/; HttpOnly;`;
  },
  setExpiresIn: (expiresIn: Date) => {
    set({ expiresIn });
  },
  getUserInfo: (user: any) => {
    set({ user });
  },
  saveUserId: (userId: string) => {
    set({ userId });
  },
  updateIsEkyc: (isEkyc: boolean) => {
    set({ isEkyc });
  },
  logout: () => {
    set({
      isAuthenticated: false,
      accessToken: null,
      isEkyc: false,
      // không reset để dùng cho việc login with face
      // refreshToken: null,
      //gán user = null để reset phiên chat
      user: null,
      edxToken: null,
    });
    document.cookie =
      "accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  },
  saveUserInfo: (user: any) => set({ user }),
  rememberInfo: (isSaveInfo, username) => set({ isSaveInfo, username }),
  rememberSession: (refreshToken) => set({ refreshToken }),
});
