import { notification } from "antd";

interface NotifySuccessProps {
  message: string;
  description?: string;
}

export const notifySuccess = (args: NotifySuccessProps | string) => {
  const iconUrl = "/assets/img/success-outline.png";

  let message, description;

  if (typeof args === "string") {
    message = args;
    description = undefined;
  } else {
    ({ message, description } = args);
  }

  notification.success({
    message: (
      <div>
        <div className="text-title-14">{message}</div>
        {description ? (
          <div className="text-description-14">{description}</div>
        ) : null}
      </div>
    ),
    icon: (
      <img src={iconUrl} alt="Success Icon" style={{ width: 24, height: 24 }} />
    ),
    duration: 2,
  });
};

export const notifyError = (message: any) => {
  const iconUrl = "/assets/icons/error.svg";
  notification.error({
    message: message,
    icon: (
      <img src={iconUrl} alt="Success Icon" style={{ width: 24, height: 24 }} />
    ),
    duration: 2,
  });
};

export const notifyWarning = (message: any) => {
  const iconUrl = "/assets/icons/waning-notify.png";
  notification.error({
    message: message,
    icon: (
      <img src={iconUrl} alt="Success Icon" style={{ width: 24, height: 24 }} />
    ),
    duration: 2,
  });
};
