import axios from "axios";
import { useAuthStore } from "../../stores/stores";

export const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const axiosConfigWithoutNotify = axios.create({
  baseURL: BASE_URL,
  timeout: 600000,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosConfigWithoutNotify.interceptors.request.use(
  (config) => {
    const accessToken = useAuthStore.getState().accessToken;
    if (!config.headers["Authorization"] || accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosConfigWithoutNotify.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 403 && !prevRequest?.sent) {
      prevRequest.sent = true;
      try {
        const refreshToken = useAuthStore.getState().refreshToken;
        const accessToken = useAuthStore.getState().accessToken;
        if (refreshToken && accessToken) {
          prevRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axiosConfigWithoutNotify(prevRequest);
        }
      } catch (error) {
        location.replace("/login");
      }
    } else if (error?.response?.status === 400) {
    } else if (error?.response?.status === 401) {
      // unauthorize
      const logout = useAuthStore.getState().logout;
      logout();
      return Promise.reject(error);
    } else if (error?.response?.status === 503) {
      //server down
      const logout = useAuthStore.getState().logout;
      logout();
      return Promise.reject(error);
    } else if (error?.response?.status === 500) {
    }
    return Promise.reject(error);
  }
);
