import {AxiosResponse} from "axios";
import {axiosConfig} from "../../config/api/configApi";

export const getRoles: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfig.get(`/roles`)
}

export const searchRoles: (dataSearch: any) => Promise<AxiosResponse<any>> = (dataSearch) => {
    return axiosConfig.post('/roles/search', dataSearch)
}

export const createRole: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfig.post('/roles', data)
}

export const editRole: (id: string, dataEdit: any) => Promise<AxiosResponse<any>> = (id, dataEdit) => {
    return axiosConfig.put(`/roles/${id}`, dataEdit)
}

export const viewInfoRole: (id: string) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfig.get(`/roles/get-by-id/${id}`)
}

export const deleteRole: (id: string) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfig.delete(`/roles/delete/${id}`)
}
