import { StudyScheduleSlice, studyScheduleSlice } from "./study-schedule/slice";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  AccountUserSlice,
  createAccountUserSlice,
} from "./list-account-users/slices";
import {
  AccountTeacherSlice,
  createAccountTeachersSlice,
} from "./list-account-teachers/slices";
import {
  AccountMinistrySlice,
  createMinistryTeachersSlice,
} from "./list-account-ministry/slice";
import { IRolesSlice, rolesSlices } from "./list-roles/slices";
import { AuthState, AuthTokenSlice } from "./auth/slice";
import { courseState, coursesSlice } from "./courses/slice";
import { curriculumSlice, curriculumState } from "./curriculum/slice";
import { courseDetailSlice, courseDetailState } from "./course-detail/slice";
import { NotificationState, notificationSlice } from "./notification/slice";
import { globalSlice, globalState } from "./global/global";
import { savedCourseState, savedSlice } from "./saved-course/slice";
import { IdentifyEkycState, identifyEkycSlice } from "./identify-ekyc/slice";
import { UserInfoState, userInfoStore } from "./user-info/slice";
import {
  learningCourseSlice,
  learningCourseState,
} from "./learning-course/slice";
import {
  ExamSupervisorState,
  examSupervisorSlice,
} from "./exam-supervisor/slice";
import { BlogAndForum, blogAndForumState } from "./blog-and-forum";
import {
  createSurveyRespondSlice,
  SurveyRespondSlice,
} from "./my-survey/slice";
import { certificateSlice, CertificateState } from "./certificate";
import { UniversitySlice, UniversityState } from "./university";

export const useAppStore = create<
  AccountUserSlice & AccountTeacherSlice & AccountMinistrySlice
>()(
  devtools(
    (...a) => ({
      ...createAccountUserSlice(...a),
      ...createAccountTeachersSlice(...a),
      ...createMinistryTeachersSlice(...a),
    }),
    {
      name: "list-account",
    }
  )
);

export const useRolesStore = create<IRolesSlice>()(
  devtools<IRolesSlice>(
    (...b) => ({
      ...rolesSlices(...b),
    }),
    {
      name: "list-roles",
    }
  )
);

export const useAuthStore = create<AuthState>()(
  devtools(
    persist<AuthState>(
      (...a) => ({
        ...AuthTokenSlice(...a),
      }),
      { name: "auth-storage" }
    ),
    { name: "auth" }
  )
);

export const useSurveyRespond = create<SurveyRespondSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createSurveyRespondSlice(...a),
      }),
      { name: "surveyRespond-storage" }
    ),
    { name: "surveyRespond" }
  )
);

export const useCoursesStore = create<courseState>()(
  devtools<courseState>(
    (...b) => ({
      ...coursesSlice(...b),
    }),
    {
      name: "courses",
    }
  )
);

export const useCurriculumStore = create<curriculumState>()(
  devtools<curriculumState>(
    (...b) => ({
      ...curriculumSlice(...b),
    }),
    {
      name: "curriculum",
    }
  )
);

export const useCourseDetailStore = create<courseDetailState>()(
  devtools<courseDetailState>(
    (...b) => ({
      ...courseDetailSlice(...b),
    }),
    {
      name: "courseDetail",
    }
  )
);

export const useNotificationStore = create<NotificationState>()(
  devtools<NotificationState>(
    (...b) => ({
      ...notificationSlice(...b),
    }),
    {
      name: "notification",
    }
  )
);
export const savedCourseStore = create<savedCourseState>()(
  devtools<savedCourseState>(
    (...b) => ({
      ...savedSlice(...b),
    }),
    {
      name: "notification",
    }
  )
);

export const globalStore = create<globalState>()(
  devtools<globalState>(
    (...b) => ({
      ...globalSlice(...b),
    }),
    {
      name: "notification",
    }
  )
);

export const useIdentifyEkycStore = create<IdentifyEkycState>()(
  devtools<IdentifyEkycState>(
    (...b) => ({
      ...identifyEkycSlice(...b),
    }),
    {
      name: "identifyEkyc",
    }
  )
);

export const useUserInfoStore = create<UserInfoState>()(
  devtools(
    persist<UserInfoState>(
      (...a) => ({
        ...userInfoStore(...a),
      }),
      { name: "userInfo" }
    )
  )
);
export const learningCourseStore = create<learningCourseState>()(
  devtools<learningCourseState>(
    (...b) => ({
      ...learningCourseSlice(...b),
    }),
    {
      name: "learningCourse",
    }
  )
);
export const examSupervisorStore = create<ExamSupervisorState>()(
  devtools<ExamSupervisorState>(
    (...b) => ({
      ...examSupervisorSlice(...b),
    }),
    {
      name: "examSupervisor",
    }
  )
);

export const studyScheduleStore = create<StudyScheduleSlice>()(
  devtools<StudyScheduleSlice>(
    (...b) => ({
      ...studyScheduleSlice(...b),
    }),
    {
      name: "notification",
    }
  )
);
export const settingAttendanceStore = create<BlogAndForum>()(
  devtools<BlogAndForum>(
    (...b) => ({
      ...blogAndForumState(...b),
    }),
    {
      name: "setting-attendance",
    }
  )
);

export const blogAndForumStore = create<BlogAndForum>()(
  devtools<BlogAndForum>(
    (...b) => ({
      ...blogAndForumState(...b),
    }),
    {
      name: "blog-and-forum",
    }
  )
);

export const certificateStore = create<CertificateState>()(
  devtools<CertificateState>(
    (...b) => ({
      ...certificateSlice(...b),
    }),
    {
      name: "certificate",
    }
  )
);

export const UniversityStore = create<UniversityState>()(
  devtools<UniversityState>(
    (...b) => ({
      ...UniversitySlice(...b),
    }),
    {
      name: "university",
    }
  )
);