import { StateCreator } from "zustand";


export interface AccountUserSlice {
	accountInfo: null,
	setAccountDetail: (dataInfo: any) => void,
	updateStatus: (status: boolean, id: number[]) => void,
	changePassword: (body: any) => void,
}


export const createAccountUserSlice: StateCreator<AccountUserSlice> = (set, get) => ({
	accountInfo: null,
	setAccountDetail: (dataInfo) => {
		set({ accountInfo: dataInfo })
	},
	updateStatus: async (status, id) => {
		/*try {
			const response = await changeStatusAccount(status, id)
			const {data} = response
			set(() => ({loading: false, result: data}))
		} catch (err) {
			set(() => ({loading: false, error: true}))
		}*/
	},
	changePassword: async (body: any) => {
		// 	set(() => ({result: null, loading: true}))
		// 	try {
		// 		const response = await changePassword(body)
		// 		const {data} = response.data
		// 		set(() => ({loading: false, result: data}))
		// 	} catch (err: any) {
		// 		set(() => ({loading: false, error: true, result: err.response ? err.response.data : null}))
		// 	}
	}
})
