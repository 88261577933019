import { StateCreator } from "zustand";
import {
  CourseAdministrator,
  CourseInsights,
  CurriculumDetail,
  CurriculumRecommend,
  CurriculumRoadmap,
} from "../../types/curriculum";
import { IndustryInfo } from "../../types/course";

export interface curriculumState {
  detail: CurriculumDetail;
  recommendList: CurriculumRecommend[];
  roadmap: CurriculumRoadmap;
  courseAdministrator: CourseAdministrator;
  courseInsights: CourseInsights;
  industrieCurriculum: IndustryInfo[];
  setDetail: (data: CurriculumDetail) => void;
  setRecommendList: (data: CurriculumRecommend[]) => void;
  setRoadmap: (data: CurriculumRoadmap) => void;
  setCourseAdministrator: (data: CourseAdministrator) => void;
  setCourseInsights: (data: CourseInsights) => void;
  setIndustrieCurriculum: (data: IndustryInfo[]) => void;
}

export const curriculumSlice: StateCreator<curriculumState> = (set) => ({
  detail: {
    id: 0,
    name: "",
    description: "",
    star: 0,
    cost: 0,
    industry: {
      id: 0,
      name: "",
      code: 0,
      industryGroupCode: 0,
      status: true,
    },
    type: 0,
    scheduleType: 0,
    enterpriseUuid: {
      created: "",
      modified: "",
      name: "",
      slug: "",
      active: false,
      country: "",
    },
    updatedDate: "",
    createdDate: "",
    imagePath: "",
    isTrending: false,
    isDeleted: false,
    studentCount: 0,
    template: "",
    templateStatusId: "",
    learningStatus: 0,
    isAbleToComment: false,
    isFreeCourse: false,
  },
  recommendList: [],
  roadmap: {
    courses: [],
    total: 0,
  },
  courseAdministrator: {
    enterpriseName: "",
    information: "",
    logo: "",
    coverImage: "",
    shortDescription: "",
    totalCourse: 0,
    totalLearner: 0,
    starRate: 0,
    totalStudent: 0,
    slug: "",
    totalCmt: 0,
    enterpriseNameEn: "",
  },
  courseInsights: {
    comments: [],
    count: 0,
  },
  industrieCurriculum: [],
  setDetail: (detail) => {
    set(() => ({
      detail: detail,
    }));
  },
  setRecommendList: (data: CurriculumRecommend[]) => {
    set(() => ({ recommendList: data }));
  },
  setRoadmap: (data: CurriculumRoadmap) => {
    set(() => ({ roadmap: data }));
  },
  setCourseAdministrator: (data: CourseAdministrator) => {
    set(() => ({ courseAdministrator: data }));
  },
  setCourseInsights: (data: CourseInsights) => {
    set(() => ({ courseInsights: data }));
  },
  setIndustrieCurriculum: (data: IndustryInfo[]) => {
    set(() => ({ industrieCurriculum: data }));
  },
});
