import create from "zustand";
import { StateCreator } from "zustand";
import { UniversityInfo } from "../../types/course";

export interface UniversityState {
  uuid: string;
  name: string;
  slug: string;
  color: string;
  logo: string;

  setUniversityInfo: (value: UniversityInfo) => void;
}

export const UniversitySlice: StateCreator<UniversityState> = (set) => ({
  uuid: "",
  name: "",
  slug: "",
  color: "",
  logo: "",

  setUniversityInfo: (value: UniversityInfo) => {
    set({
      uuid: value.uuid,
      name: value.name,
      slug: value.slug,
      color: value.color,
      logo: value.logo,
    });
  },
});
