import { StateCreator } from "zustand";
import { CertificateType } from "../../types/certificate";

export interface CertificateState {
  certificate: CertificateType;
  setCertificate: (data: CertificateType) => void;
}

export const certificateSlice: StateCreator<CertificateState> = (set) => ({
  certificate: {
    courseName: "",
    fullName: "",
    certificateTemplate: "",
    dayFinished: "",
  },

  setCertificate: (detail) => {
    set(() => ({
      certificate: detail,
    }));
  },
});
