import { StateCreator } from "zustand";

export interface ListSurveySection {
  surveyId: number;
  sectionName?: string;
  sectionDescription?: string;
  orderNumber?: number;
  isDeleted?: boolean;
  moocSurveyQuestion?: ListQuestion[];
}

export interface ListQuestion {
  questionId: number;
  surveySectionId: number;
  questionName?: string;
  questionDescription?: string;
  questionType?: number;
  questionAnswer?: any;
  orderNumber?: number;
  isRequired?: boolean;
  questionsPoints?: string;
  responseValidationType?: string;
  responseValidationOperator?: string;
  responseValidationValue?: string;
  responseValidationCustomErrorText?: string;
  moocSurveyQuestionAnswer?: ListAnswer[];
}

export interface ListAnswer {
  answerId?: number;
  surveyQuestionId?: number;
  answerText?: string;
  answerImage?: string;
  linkedAnswerText?: string;
  linkedAnswerImage?: string;
  answerOrderNumber?: string;
  linkedAnswerOrder?: string;
  linkedAnswerOrderNumber?: string;
  isOther?: boolean;
}

export interface SurveyRespondSlice {
  nameSurvey: string | null;
  surveyData: ListSurveySection[];
  setSurveyData: (data: ListSurveySection[]) => void;
  updateAnswer: (
    surveyId: number,
    sectionId: number,
    questionId: number,
    answerValue: any
  ) => void;
}

export const createSurveyRespondSlice: StateCreator<SurveyRespondSlice> = (
  set,
) => ({
  nameSurvey: null as string | null,
  surveyData: [] as ListSurveySection[],

  setSurveyData: (data: ListSurveySection[]) => set(()=>({ surveyData: data })),

  updateAnswer: (
    surveyId: number,
    sectionId: number,
    questionId: number,
    answerValue: any
  ) =>
    set((state: any) => {
      const newData = state.surveyData.map((section: ListSurveySection) => {
        if (section.surveyId === surveyId) {
          // Kiểm tra surveyId thay vì sectionId
          const newQuestions = section.moocSurveyQuestion?.map((question) => {
            if (
              question.surveySectionId === sectionId &&
              question.questionId == questionId
            ) {
              return { ...question, questionAnswer: answerValue };
            }
            return question;
          });
          return { ...section, moocSurveyQuestion: newQuestions };
        }
        return section;
      });
      return { surveyData: newData };
    }),
});
