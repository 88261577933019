import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigWithoutNotify } from "../../config/api/configApiWithoutNotify";

export const extendExam: (sessionId: string) => Promise<AxiosResponse<any>> = (
  sessionId
) => {
  return axiosConfig.post(`/v2/api/course/structure/exam/extend/${sessionId}`);
};

export const cancelExam: (sessionId: string) => Promise<AxiosResponse<any>> = (
  sessionId
) => {
  return axiosConfig.put(`/v2/api/course/structure/exam/cancel/${sessionId}`);
};

export const reportExam: (payload: {
  blockId: number;
  sessionId: string;
  content: string;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(`/v2/api/course/structure/exam/report`, payload);
};

export const examMark: (payload: {
  blockId: number;
  sessionId: string;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(`/v2/api/course/structure/exam/mark`, payload);
};

export const submitHistoryDate: (
  blockId: number
) => Promise<AxiosResponse<any>> = (blockId) => {
  return axiosConfig.get(
    `/v2/api/course/structure/exam/submit-history-date/${blockId}`
  );
};

export const startSingleQuiz: (payload: {
  blockId: number;
  quizId: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(
    `/v2/api/course/structure/exam/start-single`,
    payload
  );
};

export const pauseExam: (payload: {
  blockId: number;
  currentQuizId: number;
  quizRequests: any;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.put(`/v2/api/course/structure/exam/pause`, payload);
};

export const continueExam: (payload: {
  blockId: number;
  sessionId: string;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.put(`/v2/api/course/structure/exam/continue`, payload);
};

export const submitExam: (payload: {
  blockId: number;
  quizRequests: any;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(`/v2/api/course/structure/exam/submit`, payload);
};

export const getUnitDetails: (id: number) => Promise<AxiosResponse<any>> = (
  blockId: number
) => {
  return axiosConfig.get(`/v2/api/course/structure/details/${blockId}`);
};

export const getExamDetails: (payload: {
  courseId: number;
  blockId: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(`/v2/api/course/structure/exam`, payload);
};

export const getCourseContentMenu: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfig.get(`/v2/open-api/course/${id}`);
};

export const getUnitDetail: (id: number) => Promise<AxiosResponse<any>> = (
  id: number
) => {
  return axiosConfig.get(`/v2/api/mooc-course-unit/get-unit/${id}`);
};

export const checkUnitComplete: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload: any) => {
  return axiosConfig.put(
    `/v2/api/mooc-course-unit/check-unit-completed`,
    payload
  );
};

export const getNoteList: (payload: any) => Promise<AxiosResponse<any>> = (
  payload: any
) => {
  return axiosConfig.post(
    `/v2/api/mooc-student-note/get-by-conditions`,
    payload
  );
};

export const getNoteHistory: (payload: any) => Promise<AxiosResponse<any>> = (
  payload: any
) => {
  return axiosConfig.post(
    `/v2/api/mooc-student-note/history/get-by-conditions`,
    payload
  );
};

export const getNoteListPinned: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload: any) => {
  return axiosConfig.post(
    `/v2/api/mooc-student-note/get-pinned-by-course`,
    payload
  );
};
export const getNoteListByUnit: (
  id: number,
  keyword: string
) => Promise<AxiosResponse<any>> = (id: number, keyword: string) => {
  return axiosConfig.get(
    `/v2/api/mooc-student-note/get-by-unit?unitId=${id}&keyword=${keyword}`
  );
};

export const addNote: (payload: any) => Promise<AxiosResponse<any>> = (
  payload: any
) => {
  return axiosConfigToUpload.post(`/v2/api/mooc-student-note/create`, payload);
};

export const editNote: (payload: any) => Promise<AxiosResponse<any>> = (
  payload: any
) => {
  return axiosConfigToUpload.put(`/v2/api/mooc-student-note/update`, payload);
};

export const deleteNote: (data: object) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.delete(`/v2/api/mooc-student-note/delete`, {
    data,
  });
};

export const getNoteDetail: (id: number) => Promise<AxiosResponse<any>> = (
  id: number
) => {
  return axiosConfig.get(`/v2/api/mooc-student-note/get-by-id/${id}`);
};

export const getSectionByCourse: (id: number) => Promise<AxiosResponse<any>> = (
  id: number
) => {
  return axiosConfig.get(
    `/v2/api/course-section/get-section-by-courseId/${id}`
  );
};

export const getSequenceBySection: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfig.get(
    `/v2/api/course-sequence/get-sequence-by-section/${id}`
  );
};

export const getUnitBySequence: (id: number, includingQuiz: boolean) => Promise<AxiosResponse<any>> = (
  id: number, includingQuiz: boolean,
) => {
  return axiosConfig.get(`/v2/api/mooc-course-unit/get-by-sequence/${id}?includingQuiz=${includingQuiz}`);
};

export const pinNote: (payload: any) => Promise<AxiosResponse<any>> = (
  payload: any
) => {
  return axiosConfig.put(`/v2/api/mooc-student-note/update-pinned`, payload);
};

export const getExamList: (
  courseId: number,
  payload: any
) => Promise<AxiosResponse<any>> = (courseId, payload) => {
  return axiosConfig.post(
    `/v2/api/course/structure/exam/search/${courseId}`,
    payload
  );
};

export const getDocumentOfCourse: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(
    `/v2/api/course-unit-reference/get-by-condition`,
    payload
  );
};

export const getTotalDocument: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfig.post(`/v2/api/course-unit-reference/get-count`, payload);
};

export const favoriteDocument: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfig.post(`/v2/api/mooc-course-unit/favorite`, payload);
};

export const dowloadDocument: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfig.post(`/v2/api/course-unit-reference/download`, payload, {
    responseType: "arraybuffer",
  });
};

export const downloadAllDocument: (
  courseId: number
) => Promise<AxiosResponse<any>> = (courseId) => {
  return axiosConfig.get(
    `/v2/api/course-unit-reference/download-all/${courseId}`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const getDocumentHistory: (
  params: any
) => Promise<AxiosResponse<any>> = (params) => {
  return axiosConfig.get(
    `/v2/api/history-course/get-unit-history-by-id?${params}`
  );
};

export const searchCourseStructure: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/api/mooc-courses/search-course-structure`,
    payload
  );
};

export const reviewUnit: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/api/unit-review/create-unit-review`, payload);
};

export const reviewSequence: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(
    `/api/sequence-review/create-sequence-review`,
    payload
  );
};

export const countLearningCourse: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/api/unit-action-histories/create-unit-action-histories`,
    payload
  );
};

export const downloadQuiz: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(
    `/api/mooc-course-block-quiz/export/excel/${id}`
  );
};
