import { StateCreator } from "zustand";
import { EkycRegisterUserResponse } from "../../types/ekycIdentify";

export interface IdentifyEkycState {
  step: number;
  data: EkycRegisterUserResponse["data"] | null;
  changeStep: (step: number) => void;
  resetState: () => void;
  updateIdentifyEkycData: (
    data: EkycRegisterUserResponse["data"] | null
  ) => void;
}

export const identifyEkycSlice: StateCreator<IdentifyEkycState> = (set) => ({
  step: 0,
  data: null,
  updateIdentifyEkycData: (data) => {
    set(() => ({ data }));
  },
  changeStep: (step: number) => {
    set(() => ({ step: step }));
  },
  resetState: () => {
    set(() => ({ step: 0, data: null }));
  },
});
