import { StateCreator } from "zustand";
import { EXAM_SUPERVISOR } from "../../constants/exam-supervisor";
import {
  ExamSupervisorData,
  ExamSupervisorSetting,
} from "../../types/examSupervisor";

export interface ExamSupervisorState {
  examSupervisorData: ExamSupervisorData | null;
  examSupervisorSetting: ExamSupervisorSetting['data'] | null;
  recordVideoTime: number;
  recordedVideo: Blob[];
  violenList: { type: EXAM_SUPERVISOR; time: string }[];
  setRecordedVideo: (data: Blob[]) => void;
  setViolenList: (data: { type: EXAM_SUPERVISOR; time: string }[]) => void;
  setSaveRecordVideoTime: (count: number) => void;
  resetSupervisorData: () => void;
  setExamSupervisorData: (data: ExamSupervisorData) => void;
  setExamSupervisorSetting: (data: ExamSupervisorSetting['data']) => void;
}

export const examSupervisorSlice: StateCreator<ExamSupervisorState> = (
  set
) => ({
  examSupervisorData: null,
  examSupervisorSetting: null,
  recordVideoTime: 0,
  recordedVideo: [],
  violenList: [],
  setExamSupervisorData: (data) => set({ examSupervisorData: data }),
  setExamSupervisorSetting: (data) => set({ examSupervisorSetting: data }),
  setRecordedVideo: (data) => set({ recordedVideo: data }),
  setViolenList: (data) => {
    set((state) => ({
      violenList: [...state.violenList, ...data],
    }));
  },
  setSaveRecordVideoTime: (count) => set({ recordVideoTime: count }),
  resetSupervisorData: () =>
    set({
      recordedVideo: [],
      recordVideoTime: 0,
      violenList: [],
      examSupervisorSetting: null,
      examSupervisorData: null,
    }),
});
