import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { App, ConfigProvider, Spin } from "antd";
import { DirtyProvider } from "./contexts/DirtyProvider";
import WebSocketComponent from "./components/websocket/WebSocketComponent";
import { Suspense, useEffect } from "react";
import { UniversityStore } from "./stores/stores";

function DefaultApp() {
  const { color } = UniversityStore();
  useEffect(() => {
    window.document.title = `${process.env.REACT_APP_PROJECT_NAME}` || ``;
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: { fontFamily: "Inter", colorPrimary: color || "#9F224E" },
        components: {
          Progress: {
            defaultColor: color || "#9F224E",
          },
        },
      }}
    >
      <App>
        <DirtyProvider>
          <WebSocketComponent>
            <Suspense
              fallback={
                <div className="loading-global">
                  <Spin spinning></Spin>
                </div>
              }
            >
              <RouterProvider router={router} />
            </Suspense>
          </WebSocketComponent>
        </DirtyProvider>
      </App>
    </ConfigProvider>
  );
}

export default DefaultApp;
