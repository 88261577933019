import {StateCreator} from "zustand";


export interface AccountTeacherSlice {
    accountTeachers: [],
    error: boolean,
    success: boolean,
    result: null,
    resultResponse: null,
    setDataTeachers: (data: any) => void,
}


export const createAccountTeachersSlice: StateCreator<AccountTeacherSlice> = (set, get) => ({
    accountTeachers: [],
    loading: false,
    error: false,
    success: false,
    result: null,
    resultResponse: null,
    setDataTeachers: (data) => {
        set(() => ({accountTeachers: data, success: true}))
    }
})
