import { StateCreator } from "zustand";
import { CourseContentMenu, Note, Unit } from "../../types/course";

interface Highlight {
  text: string;
  start: number;
  end: number;
  id: any;
  isNew?: boolean;
}

export interface learningCourseState {
  learningCourseData: CourseContentMenu;
  unitData: Unit;
  totalNoteByUnit: number;
  notes: Note[];
  noteId: number | null;
  delNoteId: number | null;
  isAddMarked: boolean;
  isCheckAddMarked: boolean;
  highlights: Highlight[];
  editingIndex: number | null;
  tab: string;
  sectionId: number | null;
  sequenceId: number | null;
  unitId: number | null;
  videoTime: number;
  setLearningCourseData: (data: CourseContentMenu) => void;
  setUnitData: (data: Unit) => void;
  setTotalNoteByUnit: (data: number) => void;
  setNotes: (data: Note[]) => void;
  setNoteId: (data: number) => void;
  setDelNoteId: (data: number | null) => void;
  setIsAddMarked: (data: boolean) => void;
  setIsCheckAddMarked: (data: boolean) => void;
  setHighlights: (data: Highlight[]) => void;
  setEditingIndex: (data: number | null) => void;
  setTab: (tab: string) => void;
  setSectionId: (id: number) => void;
  setSequenceId: (id: number) => void;
  setUnitId: (id: number | null) => void;
  setVideoTime: (id: number) => void;
}

export const learningCourseSlice: StateCreator<learningCourseState> = (
  set
) => ({
  learningCourseData: {
    id: 0,
    name: "",
    code: "",
    description: "",
    image: "",
    template: "",
    title: "",
    courseUrl: "",
    createdDate: "",
    totalStudents: 0,
    publicDate: "",
    completedPercentage: 0,
    isRegistered: false,
    isCommented: false,
    isSaved: false,
    message: "",
    industries: [],
    sections: [],
    endDate: "",
  },
  unitData: {
    id: 0,
    name: "",
    orderNumber: 0,
    block: [],
    timeCompletedCondition: 0,
    sequenceId: 0,
    children: [],
    module: "",
    documents: [],
    isCompleted: false,
    totalView: 0,
    totalShare: 0,
    totalDownload: 0,
    fileName: ""
  },
  totalNoteByUnit: 0,
  notes: [],
  noteId: null,
  delNoteId: null,
  isAddMarked: false,
  isCheckAddMarked: false,
  highlights: [],
  editingIndex: null,
  tab: "1",
  sectionId: null,
  sequenceId: null,
  unitId: null,
  videoTime: 0,
  setLearningCourseData: (data: CourseContentMenu) => {
    set(() => ({ learningCourseData: data }));
  },
  setUnitData: (data: Unit) => {
    set(() => ({ unitData: data }));
  },
  setTotalNoteByUnit: (data: number) => {
    set(() => ({ totalNoteByUnit: data }));
  },
  setNotes: (data: Note[]) => {
    set(() => ({ notes: data }));
  },
  setNoteId: (data: number) => {
    set(() => ({ noteId: data }));
  },
  setDelNoteId: (data: number | null) => {
    set(() => ({ delNoteId: data }));
  },
  setIsAddMarked: (data: boolean) => {
    set(() => ({ isAddMarked: data }));
  },
  setIsCheckAddMarked: (data: boolean) => {
    set(() => ({ isCheckAddMarked: data }));
  },
  setHighlights: (data: Highlight[]) => {
    set(() => ({ highlights: data }));
  },
  setEditingIndex: (data: number | null) => {
    set(() => ({ editingIndex: data }));
  },
  setTab: (tab) => set({ tab }),

  setSectionId: (id) => set({ sectionId: id }),
  setSequenceId: (id) => set({ sequenceId: id }),
  setUnitId: (id) => set({ unitId: id }),
  setVideoTime: (time) => set({ videoTime: time }),
});
