import { StateCreator } from "zustand";
import { IndustryInfo, UniversityInfo } from "../../types/course";

export interface savedCourseState {
  industries: IndustryInfo[],
  universities: UniversityInfo[];
  setIndustries: (data: IndustryInfo[]) => void;
  setUniversities: (data: UniversityInfo[]) => void;

}

export const savedSlice: StateCreator<savedCourseState> = (set) => ({
  industries: [],
  universities: [],

  setIndustries: (data: IndustryInfo[]) => {
    set(() => ({ industries: data }));
  },
  setUniversities: (data: UniversityInfo[]) => {
    set(() => ({ universities: data }));
  },
});
