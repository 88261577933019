import create from "zustand";
import { StateCreator } from "zustand";

export interface globalState {
  isFooterDefault: boolean;
  schoolId: string;
  reload: boolean;
  isFullscreenQuiz: boolean;
  registerStep: number;

  setFooterDefault: () => void;
  setSchoolId: (id: string) => void;
  setReload: (data: boolean) => void;
  setIsFullscreenQuiz: (data: boolean) => void;
  setRegisterStep: (data: number) => void;
}

export const globalSlice: StateCreator<globalState> = (set) => ({
  isFooterDefault: true,
  schoolId: "",
  reload: true,
  isFullscreenQuiz: false,
  registerStep: 1,

  setFooterDefault: () => set((state) => ({ isFooterDefault: false })),
  setSchoolId: (state: string) => {
    set(() => ({
      schoolId: state,
    }));
  },
  setReload: (value: boolean) => {
    set({ reload: value });
  },
  setIsFullscreenQuiz: (value: boolean) => {
    set({ isFullscreenQuiz: value });
  },
  setRegisterStep: (value: number) => {
    set({ registerStep: value });
  },
});
