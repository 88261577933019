import { StateCreator } from "zustand";

export interface AccountMinistrySlice {
    accountMinistries: Array<any>,
    error: boolean,
    success: boolean,
    setDataMinistries: (dataMinistries: any) => void,
}


export const createMinistryTeachersSlice: StateCreator<AccountMinistrySlice> = (set, get) => ({
    accountMinistries: [],
    error: false,
    success: false,
    setDataMinistries: (dataMinistries) => {
        set(() => ({ accountMinistries: dataMinistries, success: true }))

    }
})
