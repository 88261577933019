import { StateCreator } from "zustand";

export interface NotificationState {
  refetch: boolean;
  onRefetch: () => void;
}

export const notificationSlice: StateCreator<NotificationState> = (set) => ({
  refetch: false,

  onRefetch: () => {
    set((state) => ({ refetch: !state.refetch }));
  },
});
